<template>
  <div>
    <h3>在线记账</h3>
    <!-- 项目信息详情 -->
    <PreviewList
      v-if="IsStanding"
      @closeDialog="closeDialog"
      ref="IsStanding"
    />
    <!-- 根据条件搜索 -->
    <div class="searchItem">
      <el-form
        :model="form"
        label-position="left"
        label-width="80px"
        inline
        ref="form"
      >
        <el-form-item label="收支类别：">
          <el-select v-model="form.fileType" style="width: 200px">
            <el-option
              v-for="(item, index) in types"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="上传时间：">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd"
            @change="changeTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户名：">
          <el-input
            style="width: 190px"
            v-model="form.userName"
            placeholder="用户"
          >
          </el-input>
        </el-form-item>
        <div>
          <el-button class="query-btn" type="primary" @click="search"
            >搜索</el-button
          >
          <el-button class="query-btn" type="primary" @click="AllSearch()"
            >导出搜索结果</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- table列表 -->
    <div class="TableList">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
      >
        <template slot="fileType" slot-scope="scope">
          {{ scope.row.fileType == 1 ? "收入" : "支出" }}
        </template>
        <template slot="files" slot-scope="scope">
          <el-image
            :src="
              baseUrl +
              'admin/file/get?ossFilePath=' +
              JSON.parse(scope.row.files)[0].imgUrl
            "
            :preview-src-list="srcList"
            @click="previewList(scope.row)"
          >
            <div slot="error" class="image-slot">
              <span @click="previewList(scope.row)">{{
                JSON.parse(scope.row.files)[0].FilesName
              }}</span>
            </div>
          </el-image>
        </template>
        <template slot="createTime" slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
import { incomeAndExpense } from "../tableColumn";
import TablePage from "@/components/table/table-page";
import PreviewList from "./component/PreviewList";
import { accountingSelect, batchDownload } from "@/api/ChargeUp";

export default {
  data() {
    return {
      form: {
        fileType: "", //收支类别
        time: ["", ""], //上传时间
        userName: "", //用户名
      },
      types: [
        {
          label: "收入",
          value: "1",
        },
        {
          label: "支出",
          value: "2",
        },
      ],
      columns: incomeAndExpense, //table表头
      tableData: [], //table数据
      query: {
        size: 10,
        current: 1,
      }, //分页

      total: 0, //数量
      loading: false, //加载状态
      srcList: [], //存储预览预览图片坐标
      baseUrl: window.globalUrl.HOME_API,
      IsStanding: false, //是否打开图片预览
      // guarantee: [], //担保方式
    };
  },
  components: {
    TablePage,
    PreviewList,
  },

  mounted() {
    this.getList();
  },

  methods: {
    //筛选时间
    changeTime(val) {
      this.startTime = val[0];
      this.endTime = val[1];
      // this.initData();
      console.log(this.startTime, this.endTime);
    },

    //收支列表
    async getList() {
      let params = {
        ...this.query,
      };
      let res = await accountingSelect(params);
      this.tableData = res.data ;
      console.log("this.tableData",this.tableData);
    },

    //分页单页切换
    handleCurrentChange(page) {
      this.query.size = page;
      this.getList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        size: 1,
        current: size,
      };
      this.getList();
    },
    //预览图片
    previewList(obj) {
      this.IsStanding = true;
      this.$nextTick(() => {
        this.$refs.IsStanding.init(obj);
      });
    },
    //图片预览
    BigImg(Url) {
      let ImgUrl = this.baseUrl + "admin/file/get?ossFilePath=" + Url;
      this.srcList.push(ImgUrl);
    },
    //关闭支付凭证预览
    closeDialog(IsStanding) {
      this.IsStanding = IsStanding;
      this.getList();
    },

    //导出搜索结果
    async AllSearch() {
      let params = {
        ...this.form,
        ...this.query,
        createStartTime: this.startTime,
        createEndTime: this.endTime,
      };
      let res = await batchDownload(params);
      if (res) {
        let blob = res;
        const fileName = "收支记录统计.zip";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    //搜索结果
    async search() {
      let params = {
        ...this.form,
        ...this.query,
      };
      let res = await accountingSelect(params);
      this.tableData = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
/*根据条件搜索*/
.searchItem {
  .el-form {
    width: 100%;
    display: flex;
  }

  /deep/.el-form-item {
    width: 25% !important;
    .el-input {
      width: 200px;
    }
    .el-form-item__label {
      width: 100px !important;
    }
    .el-form-item__content {
      width: 200px;
    }
    .el-date-editor {
      width: 200px;
    }
  }
}
/deep/tbody {
  tr {
    td {
      img {
        width: 100px;
        height: 50px;
      }
    }
  }
}
</style>
